import request from '@/utils/request';
/**
 *  fetch: 获取
 *  update: 修改
 *  add: 增加
 *  del: 删除
 */
// Slide 幻灯片管理
// 幻灯片列表

export function fetchSlideList(data) {
  return request({
    url: '/advert/wechat/slide',
    method: 'get',
    params: data
  });
} // 添加幻灯片

export function addSlide(data) {
  return request({
    url: '/advert/wechat/slide',
    method: 'post',
    data: data
  });
} // 幻灯片编辑

export function updateSlide(id, data) {
  return request({
    url: '/advert/wechat/slide/' + id,
    method: 'put',
    data: data
  });
} // 幻灯片编辑

export function fetchSlideDetail(id) {
  return request({
    url: '/advert/wechat/slide/' + id,
    method: 'get'
  });
} // 删除幻灯片

export function delSlide(data) {
  return request({
    url: '/advert/wechat/slide',
    method: 'delete',
    data: data
  });
}
export function updateSlideEnabled(data) {
  return request({
    url: '/advert/wechat/slide/enabled',
    method: 'post',
    data: data
  });
} // 弹窗广告列表

export function fetchPopupList(data) {
  return request({
    url: '/advert/wechat/popup',
    method: 'get',
    params: data
  });
} // 添加幻灯片

export function addPopup(data) {
  return request({
    url: '/advert/wechat/popup',
    method: 'post',
    data: data
  });
} // 幻灯片编辑

export function updatePopup(id, data) {
  return request({
    url: '/advert/wechat/popup/' + id,
    method: 'put',
    data: data
  });
} // 幻灯片编辑

export function fetchPopupDetail(id) {
  return request({
    url: '/advert/wechat/popup/' + id,
    method: 'get'
  });
} // 删除幻灯片

export function delPopup(data) {
  return request({
    url: '/advert/wechat/popup',
    method: 'delete',
    data: data
  });
}
export function updatePopupEnabled(data) {
  return request({
    url: '/advert/wechat/popup/enabled',
    method: 'post',
    data: data
  });
}