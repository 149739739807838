import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchSlideList, delSlide, updateSlideEnabled } from "@/api/advert";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
export default {
  name: "wechatSlideList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20
      },
      hightlight: false,
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      selectList: [],
      selectTotal: [],
      lineStatus: _global.lineStatus,
      chargeType: _global.chargeType,
      // 固件
      firmDialog: false,
      firmOptions: [],
      firmForm: {
        slide_id: "",
        firmware_id: ""
      }
    };
  },
  created: function created() {
    this.init();
    this.getTableData();
  },
  methods: {
    init: function init() {
      console.log(this.$route.params.searchForm);

      if (this.$route.params && this.$route.params.searchForm) {
        this.searchForm = JSON.parse(this.$route.params.searchForm);
      }

      if (this.$route.query && this.$route.query.searchForm) {
        this.searchForm = JSON.parse(this.$route.query.searchForm);
      }
    },
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      console.log(data);
      fetchSlideList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.$message({
          type: "warning",
          message: err.data["message"]
        });

        _this.tableLoading = false;
      });
    },
    handleSearch: function handleSearch() {
      // this.searchForm.page = 1;
      // this.getTableData();
      var searchForm = JSON.stringify(this.searchForm);
      this.$router.push({
        path: "/charge/equipment/list",
        query: {
          searchForm: searchForm
        }
      });
    },
    handleSelectChange: function handleSelectChange(val) {
      this.selectCount = val.length;
      this.selectList = val;
    },
    // 合并
    handleChangeSel: function handleChangeSel() {
      // 批量操作或下一页执行合并选择
      this.selectTotal = this.selectTotal.concat(this.selectList);
    },
    // 清空
    handleClear: function handleClear() {
      this.selectTotal = [];
    },
    // 抽取id
    handleSelectList: function handleSelectList(data) {
      var arr = [];

      for (var i in data) {
        arr.push(data[i].id);
      }

      return arr.join(",");
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log("页数");
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleDel: function handleDel(row) {
      var _this2 = this;

      var rowType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      console.log("del");
      var content = "\u60A8\u786E\u8BA4\u8981\u5220\u9664\u8BE5\u5E7B\u706F\u7247? ";
      var title = "谨慎删除";
      this.$confirm(content, title, {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "error"
      }).then(function () {
        // 批量
        console.log(row);
        var data = {
          slide_id: ""
        };

        if (rowType === 1) {
          data["slide_id"] = row.id;
        } else {
          data["slide_id"] = row;
        }

        console.log(data);
        delSlide(data).then(function (res) {
          console.log(res, "xxx");

          _this2.$message({
            type: "success",
            center: true,
            message: "删除成功"
          });

          if (rowType === 1) {
            var index = _this2.dataList.indexOf(row);

            _this2.dataList.splice(index, 1);
          } else {
            _this2.handleClear();

            _this2.getTableData();
          }
        }).catch(function (err) {
          _this2.handleClear();
        });
      }).catch(function () {});
    },
    batchDel: function batchDel() {
      this.handleChangeSel();
      var idList = this.handleSelectList(this.selectTotal);
      this.handleDel(idList, 2);
    },
    batchHiden: function batchHiden() {
      var _this3 = this;

      console.log("批量隐藏");
      this.handleChangeSel();
      var idList = this.handleSelectList(this.selectTotal);
      var data = {
        slide_id: idList,
        enabled: 0
      };
      updateSlideEnabled(data).then(function (res) {
        _this3.handleClear();

        _this3.$message({
          type: "success",
          center: true,
          message: "批量隐藏成功"
        });

        _this3.getTableData();
      }).catch(function (err) {
        _this3.handleClear();
      });
    },
    batchShow: function batchShow() {
      var _this4 = this;

      console.log("批量显示");
      this.handleChangeSel();
      var idList = this.handleSelectList(this.selectTotal);
      var data = {
        slide_id: idList,
        enabled: 1
      };
      updateSlideEnabled(data).then(function (res) {
        _this4.handleClear();

        _this4.$message({
          type: "success",
          center: true,
          message: "批量显示成功"
        });

        _this4.getTableData();
      }).catch(function (err) {
        _this4.handleClear();
      });
    },
    batchEdit: function batchEdit(id) {
      this.handleChangeSel();
      var idList = this.handleSelectList(this.dataList);
      var idArr = idList.split(",");
      var searchForm = JSON.stringify(this.searchForm);
      console.log(idArr, id); // this.$router.push({name: 'EquipmentEdit', params: {idArr: idArr, number: id}})

      this.$router.push({
        path: "/advert/wechat/slide/edit",
        query: {
          idArr: idArr,
          id: id,
          searchForm: searchForm
        }
      });
    },
    handleReturn: function handleReturn() {
      return false;
    }
  }
};